<template>
	<b-row>     
		<b-col lg="12"> 
			<b-card  class="card-company-table" v-if="profits.anual">
				<div class="d-flex justify-content-between">
				<h4 class="card-title">{{$t('Profit')}}</h4>                      
					<a class="text-muted " @click="pushRoute('my-reports')">
						<b-badge variant="primary" pill class="badge-round" > {{$t('Go to My reports')}} </b-badge>                                       
					</a>            
				</div> 
																
				<div v-if="profits.anual.length">
				<b-table
					:items="profits.anual"            
					:fields="fieldsProfits"
					responsive
					class="mb-2"
				></b-table>
				<div v-if="profits.anual">
					<div class="text-center" v-if="!profits.anual[0].isAuthorized">
					<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-2" v-b-modal.modal-authorize>
						<feather-icon
						icon="ThumbsUpIcon"
						size="16"
						/> {{$t('I agree')}}
					</b-button>
					</div>
				</div>
				</div>
				<b-alert variant="danger" show class="m-2 p-1" v-else>
				<div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" /> {{$t('No performance information')}} </div>
				</b-alert>          
			</b-card>
			<b-card v-else>     
				<b-alert variant="danger" show class="mb-0" >
					<div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" /> {{$t('There is no information for the annual return')}} </div>
				</b-alert>
			</b-card>
			<b-modal
				id="modal-authorize"
				hide-backdrop
				centered
				header-bg-variant="warning"
				ok-only
				no-close-on-backdrop
				content-class="shadow"
				title="Are you sure to accept?"
				ok-title="Accept" 
				hide-footer     
			>      
				<div>
					<label for="comments-authorize">{{$t('Comments')}}</label>
					<b-form-textarea
					id="comments-authorize"
					placeholder="Add comments, this is optional "
					rows="2"
					v-model="notes"
					class="mb-2"
					/>
					<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="closeModal">                        
					<span> <font-awesome-icon icon="fa-solid fa-close" /> {{$t('Cancel')}} </span>
					</b-button>
					<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-2 float-right" @click="accept">            
					<span v-if="isAcceptingResults"> <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" /> {{$t('In process')}}</span>
					<span v-if="!isAcceptingResults"> <font-awesome-icon icon="fa-solid fa-save" /> Ok </span>
					</b-button>
				</div>
			</b-modal>
		</b-col>
	</b-row>       
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { utils } from "@/modules/owners/mixins/utils"

export default {  
	mixins:[utils],
	directives: {
		Ripple,
	},  
	data() {
		return {
			fieldsProfits: [      
				{ key: 'ownername' },
				{ key: 'housingpoints' },
				{ key: 'shareBar' },
				{ key: 'ownerBenefit' },
				{ key: 'previousBenefit' },
				{ key: 'currentBenefit' },
				{ key: 'maintenanceFee' },
				{ key: 'remainingBalance' },
			],
			notes: '',
			isAcceptingResults: false,
		}
	},
	computed:{
		...mapState('owners', ['profits']),
		...mapState('auth', ['user', 'myContracts']),

	},
	methods: {
		...mapActions('owners', ['autorizeSettlement','pushActionsService']),	    
		closeModal() {
			this.notes = ''
			this.$root.$emit('bv::hide::modal', 'modal-authorize')
		},
		async accept(){
			this.isAcceptingResults = true
			const payload = {
				idOwner: this.user.idOwner,
				notes: this.notes
			}
			const result = await this.autorizeSettlement(payload)   
			
			this.isAcceptingResults = false  
			this.notes = ''
			this.$root.$emit('bv::hide::modal', 'modal-authorize')
		
		},
		async pushRoute(nameRoute){                        		
			const previousRoute =  this.$route.name                  
            this.$router.push({name: nameRoute })         
            await this.pushData('onClick', 'goToMyReports', 'goToMyReportsFromDashboard', previousRoute) // push a back del registro 

		},		
	}
}
</script>