<template>
	<div class="container-cards">
	
		<div class="mi-card" v-if="hotelPerformance">
			<div class="img-wrapper">
				<b-link
					v-if="!!hotelPerformance.link"
					:href="hotelPerformance.link || '#'"
					target="_blank"
					rel="noopener noreferrer"
				> <img :src="hotelPerformancePath" alt="Hotel Performance" @error="imgAlternativo" :title="!!hotelPerformance.link ? $t('click here') : ''">
				</b-link>
				<img v-else :src="hotelPerformancePath" alt="Hotel Performance" @error="imgAlternativo">
			</div>
			<!-- <div class="img-wrapper" v-else>
				<img :src="require('@/assets/images/default.jpg')" alt="not found results img">
			</div> -->
		</div>

		<div class="mi-card">
			<div class="card-transaction">
				<div class="card-body ">
					
					<div class="d-flex justify-content-between">
						<h4 class="card-title"> {{$t('Latest transactions')}}</h4>
						<a class="text-muted "  @click="pushRoute('my-benefits')">
							<b-badge variant="primary" pill class="badge-round" > {{$t('Go to My benefits')}} </b-badge>
						</a>
					</div>

					<b-table v-if="lastTransactions.length "
						:items="lastTransactions"
						responsive
						:fields="tableColumns"
						class="mb-0"
						show-empty
						empty-text="There are no records to show"
						small
						bordered
					>
						<template #cell(benefit)="row">
							<div>
								<div>
									<span><b class="text-uppercase"> {{ languageUser == 'Spanish' ? row.item.benefit : row.item.benefitEnglish}}</b> </span>
								</div>
								<div>
									<small class="text-secondary">{{$t('By')}}: </small>
									<b-badge variant="light-success" class="badge-round">
										{{ row.item.userName }}
									</b-badge>
								</div>
							</div>
						</template>
						<!-- <template #cell(createdate)="row">
						<span>{{`${(new Date(row.item.createdate)).toLocaleString("en-US",optionsDateFormat)}`}}</span>
						</template> -->
						<template #cell(quantity)="row">
							<!-- {{row.item.quantity}} -->
							<b-badge variant="light-primary" class="badge-round" >
								{{row.item.quantity}}
							</b-badge>
						</template>
					</b-table>

					<b-alert variant="danger" show class="mb-0" v-else>
						<div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" />{{$t('There is no transaction information')}}</div>
					</b-alert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { formatDate } from '@/helpers/helpers'
import { payloadEvent, userConnected} from '@/helpers/pushActions'    
import { utils } from "@/modules/owners/mixins/utils"

export default {
	mixins:[utils],
	data() {
		return {
			imgUrl: process.env.VUE_APP_IMG_SRC_API,
			imdDefault: require('@/assets/images/default.jpg'),
			optionsDateFormat: {
				weekday: "short",
				year: "numeric",
				month: "2-digit",
				day: "numeric"
			},
			tableColumns: [
				// { key: 'id', label: '#', sortable: true },
				{ key: 'benefit', label: this.$t('Benefit'), formatter: value => {
				return this.formatBenefit(value)
				}},
				{ key: 'operationdate', label: this.$t('Operation Date'), formatter: value => {
				return this.formDate(value, 'YYYY-MM-DD')
				}},
				{ key: 'quantity', label: this.$t('Quantity') },
			],
		}
	},
	computed:{
		...mapState('owners', ['hotelPerformance','lastTransactions']),
		...mapState('auth', ['myContracts', 'user']),
		hotelPerformancePath(){
			let imgDefault = this.imdDefault
			if( this.myContracts && this.hotelPerformance ){
				imgDefault = `${this.imgUrl}${this.hotelPerformance.urlgraphic}`
			}
			return imgDefault
		},
	
		languageUser(){
			const initial = 'English'
			return this.user.languageName == 'English' ? initial : 'Spanish'
		}
	},
	methods:{	
		...mapActions('owners',['pushActionsService']),
		imgAlternativo( event ){
			event.target.src = this.imdDefault
		},
		formDate(date, format){
			return formatDate({date, language: this.user?.languageName, format })
		},
		formatBenefit(value){
			if (value){
				const divided = value?.split(' | ')
				return divided?.length > 1 ? divided[1] : divided[0]
			} else return ''
		},
		async pushRoute(nameRoute){                        			      						
			const previousRoute =  this.$route.name                  
            this.$router.push({name: nameRoute })         
            await this.pushData('onClick', 'goToMyBenefits', 'goToMyBenefitsFromDashboard', previousRoute) // push a back del registro  
		},	
	}
}
</script>

<style scoped>
.container-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-block-end: 1rem;
}
.mi-card {
	/* text-align: center; */
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
  background-color: #fff;
}

.mi-card .img-wrapper {
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}
.mi-card img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

</style>