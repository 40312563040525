<template>
	<div class="dashboard">
	<BannerTop/>
    <BannerTopProfitReport/>
    <SkeletonTopCards v-if="isLoadingData" />
    <TopCards v-else/>

    <SkeletonHotelSaldos v-if="isLoadingData"/>
    <HotelSaldos v-else/>

    <SkeletonHotelsRsv  v-if="isLoadingData"/>
    <CardBookings v-else/>
 </div>
</template>

<script>

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { createMenuContracts, showAlertMessage } from '@/helpers/helpers'

import TopCards from '@/modules/owners/components/TopCards'
import BannerTop from '@/modules/owners/components/BannerTop'
import BannerTopProfitReport from '@/modules/owners/components/BannerTopProfitReport'

import HotelSaldos from '@/modules/owners/components/dashboard/HotelSaldos'
import TableShopping from '@/modules/owners/components/dashboard/TableShopping'
import CardBookings from '@/modules/owners/components/dashboard/CardBookings'
import TableRendimiento from '@/modules/owners/components/dashboard/TableRendimiento'
// Skeletons
import SkeletonTopCards from '@/modules/owners/components/skeletons/SkeletonTopCards'
import SkeletonHotelSaldos from '@/modules/owners/components/skeletons/SkeletonHotelSaldos'
import SkeletonHotelsRsv from '@/modules/owners/components/skeletons/SkeletonHotelsRsv'
import SkeletonRendimiento from '@/modules/owners/components/skeletons/SkeletonRendimiento'

export default {
	components:{
		SkeletonTopCards,
		SkeletonHotelSaldos,
		SkeletonHotelsRsv,
		SkeletonRendimiento,
		TopCards,
		BannerTop,
		BannerTopProfitReport,
		HotelSaldos,
		TableShopping,
		CardBookings,
		TableRendimiento,
  	},
	async mounted(){
		await this.loadDataDashboard()
		// addDataUserConnected({page: this.$route.name})
	},  	
	data(){
		return {
			isLoadingData: false,
		}
	},
	computed:{
		...mapState('auth', ['user','userAdmin', 'myContracts']),
		...mapState('appConfig', ['windowWidth']),
		...mapState('owners', ['profits', 'hotelPerformance','newsletter', 'languages']),
		...mapGetters('auth',['contractSelected']),
	},
	methods:{
		...mapActions('owners', ['getInitialContentDashboard']),
		...mapActions('auth', ['getContractsUser']),
		...mapMutations('auth',['setContracts','setGalleryPhotosUser','logout']),
		...mapMutations('owners',['setAnualProfit','setHotelPerformance','setLastTransactions', 'setNewsletter',
			'setNextReservation', 'setOwnerInHouse', 'setDataOwnerLogout','setLanguages']
		),
		async loadDataDashboard(){
		const stateHasData = this.stateDashboardHasData()
		if(!stateHasData){
			this.isLoadingData = true
			if( !this.myContracts ){
			await this.getContracts()
			}
			await this.getInitialData()
			this.isLoadingData = false
			//si la cuenta no tiene contratos, sale de la aplicación
			if( !this.myContracts.contracts.length ){
			const title = this.$t('No contract found')
			const msg = this.$t('Your account has no contract')
			showAlertMessage(title, 'InfoIcon', `😰😰 ${msg}`,'danger', 3000, 'bottom-right')
			setTimeout(() => {
				this.logout()
				this.setDataOwnerLogout()
				this.$router.push({name: 'auth-login'})
			}, 5000 )
			}

		}
		},
		async getContracts(){
			const contracts = await this.getContractsUser({idOwner: this.user.idOwner})
			const numberHouse = this.userAdmin ? this.userAdmin.numberHousingSelected : null
			const menu = await createMenuContracts( contracts, numberHouse )  //aqui pongo el listado de contratos, el contracto seleccionado
			this.setContracts(menu)
		},
		async getInitialData(){

			if(this.myContracts && this.myContracts.contracts.length > 0 ){

				const { id, housingid } = this.myContracts.contractSelected // id, housingid, del contrado seleccionado
				
				const idLanguage = this.user.languageId
				const idResort = this.contractSelected.resortsid

				const currentYear = new Date().getFullYear() - 1
				const payload = { idContract:id, isAnual: 'True', mailOwner: this.user.email, year: currentYear, idOwner: this.user.idOwner, housingid, language: idLanguage, resort: idResort, isOwner: true }
				const { hotelPerformance, myProfits, lastTransactions, newsletters, nextReservation, gallery, languages } = await this.getInitialContentDashboard(payload)
				//mutations
				this.setHotelPerformance(hotelPerformance)

				if( myProfits ) this.setAnualProfit(myProfits)
				const { status:isSuccess } = lastTransactions

				if(!isSuccess) this.setLastTransactions(lastTransactions)
				this.setNewsletter(newsletters)

				this.setNextReservation(nextReservation?.datos)
				this.setOwnerInHouse(nextReservation?.ownerInHouse)
				this.setGalleryPhotosUser(gallery)

				if( this.languages.length === 0 ){
					this.setLanguages(languages)
				}

				// this.setNextReservation({status: true, date: "2022-12-17"})
			}
		},
		stateDashboardHasData(){
			let hasData = true
			if( !this.myContracts || !this.hotelPerformance || !this.profits.anual || !this.newsletter.length ){
				hasData = false
			}
			return hasData
		}
	},
	watch: {
		myContracts: {
		deep: true,
		async handler() {
			this.isLoadingData = true
			await this.getInitialData()
			this.isLoadingData = false
			// addDataUserConnected({page: this.$route.name})
		},
		},
	},
}
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}
.dashboard{
  padding: 1rem;
}
</style>
