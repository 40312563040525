<template>
  <div class="topCards">
    <div class="card__container">
      <article class="card__item">
        <div class="card__profile--head">
          <div v-if="myContracts">
            <b-badge variant="primary"> {{myContracts.contractSelected.membershipcode}} </b-badge>
          </div>
          <div class="dropdown"  @click="toggleDropdown">
            <button class="dropbtn"  >
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <title>translate</title>
              <path d="M15.891 17.016h3.234l-1.641-4.359zM18.516 9.984l4.5 12h-2.016l-1.125-3h-4.734l-1.125 3h-2.016l4.5-12h2.016zM12.891 15.047l-0.797 2.063-3.094-3.094-5.016 4.969-1.406-1.406 5.109-5.016q-1.875-2.063-3-4.547h2.016q0.984 1.875 2.297 3.328 2.156-2.391 3.188-5.344h-11.203v-2.016h7.031v-1.969h1.969v1.969h7.031v2.016h-2.953q-0.469 1.5-1.547 3.398t-2.156 3.117l-0.047 0.047z"></path>
            </svg>
            {{ currentLocale.locale.toUpperCase() }}
          </button>
            <div id="myDropdown" class="dropdown-content">
              <p @click="setLocale('es')" v-if="currentLocale.locale === 'en'" >ES</p>
              <p @click="setLocale('en')" v-if="currentLocale.locale === 'es'" >EN</p>
            </div>
          </div>
        </div>
        <p class="residence" v-if="myContracts">{{myContracts.contractSelected.customInfoLong}}</p>
        <div class="card__profile--detail" v-if="user">
          <figure class="photo_profile" @click="goToMyProfile" >
              <img :src="imgUrl + user.urlphoto"  alt="logo" class="profile__img" v-if="user.urlphoto">
              <img :src="require('@/assets/images/avatars/user.png')" alt="logo" class="profile__img" v-else>
          </figure>
          <div class="card__profile--name" v-if="myContracts">
            <p>{{ user.prefix ? user.prefix: '' }} {{ user.name +' '+ user.lastname }}</p>
            <div v-if="myContracts.contractSelected.membershipcode !== 'CLASSIC'">
              <div v-if="contractExpiresThisYear">
                <p class="contractExpire">{{$t('Your contract expires this year')}}: <span>{{ myContracts.contractSelected.dateEnd }} </span></p>
              </div>
              <div v-if="!contractExpiresThisYear" >
                <p class="contractNoExpire">{{$t('Contract period')}}: <span>{{ myContracts.contractSelected.dateStart }} - {{ myContracts.contractSelected.dateEnd }} </span></p>
              </div>
            </div>

          </div>
        </div>
        <DropDownContracts class="mt-1" v-if="myContracts && myContracts.contracts.length > 1" @set-contract-selected="setSelectContract"/>
      </article>
      <article class="card__item">
        <div class="my-gallery-img" v-if="user && user.gallery && user.gallery.length > 0" >
          <b-carousel :controls="user.gallery.length > 1">
            <b-carousel-slide v-for="(photo) in user.gallery" :key="photo.id">
              <template v-slot:img>
                <img class="d-block class-name"
                :src="imgUrl + photo.urlmomentpicture"
                :alt="'image slot'+ photo.id">
              </template>
            </b-carousel-slide>
          </b-carousel>
      </div>
      <div class="my-gallery-img" v-else>
        <b-carousel controls>
          <b-carousel-slide v-for="(photo, index) in defaultGallery" :key="index">
            <template v-slot:img>
              <img
                class="d-block class-name"
                :src="photo"
                :alt="'image slot'+ photo.id">
              </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      </article>
      <article class="card__item">
        <div v-if="ownerInHouse" class="m-0 mt-1">
          <strong class="p-0">{{ $t('enjoy your visit') }}</strong>
        </div>
        <div v-if="nextReservation" >
          <div class="countdown">
            <div class="countdown__item">
              <p>{{countDown.months}}</p>
              <p>MONTHS</p>
            </div>
            <div class="countdown__item">
              <p>{{countDown.days}}</p>
              <p>DAYS</p>
            </div>
            <div class="countdown__item">
              <p>{{countDown.hours}}</p>
              <p>HOURS</p>
            </div>
          </div>
          <span class="text-checkin">{{$t('Until your next check-in')}}</span>
        </div>
        <div class="mt-3" v-else>
          <h6>{{$t('There is no date for a next check in')}}</h6>
          <p class="time-text"> {{$t('we are waiting for you')}}</p>
        </div>
      </article>
      <article class="card__item">
        <div v-if="lastNewsletter">
          <h2 class="card__name">{{lastNewsletter.name}}</h2>
          <p class="card__copy"> {{lastNewsletter.description}} </p>

          <div class="card__picture">
            <a class="btn btn-primary " :href="lastNewsletter.link" target="_blank" >{{$t('Read More')}}</a>
          </div>
        </div>
        <div v-else>
          <h2 class="card__name">{{$t('Newsletter')}}</h2>
          <p class="card__copy"> {{$t('No newsletter was found')}}</p>

          <div class="card__picture">
            <img class="img-newsleter" :src="require('@/assets/images/avatars/email.png')" alt="icon newsletter">
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import {mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import DropDownContracts from '@/modules/owners/components/DropDownContracts'
import { utils } from "@/modules/owners/mixins/utils"
import { toJson } from '@/helpers/helpers'
import { overWriteVeeValidateLocalize, overWriteFlatPickrLocalize } from "@/helpers/authHelper"

export default {
	mixins:[utils],
	components:{
		DropDownContracts,
	},
	created(){
		this.constractSelected =  this.myContracts?.contractSelected?.id || null
		if( this.nextReservation ){ // si state next reservation existe y su status es true, trabajar el countdown
			this.updateCountDown()
		}
	},
	mounted() {
		// Close the dropdown if the user clicks outside of it
		window.onclick = function(event) {
			if (!event.target.matches('.dropbtn')) {
				var dropdowns = document.getElementsByClassName("dropdown-content");
				var i;
				for (i = 0; i < dropdowns.length; i++) {
					var openDropdown = dropdowns[i];
					if (openDropdown.classList.contains('show')) {
						openDropdown.classList.remove('show');
					}
				}
			}

			if (!event.target.matches('.dropbtncontract')) {
				var dropdowns = document.getElementsByClassName("dropdown-content-contract");
				var i;
				for (i = 0; i < dropdowns.length; i++) {
					var openDropdown = dropdowns[i];
					if (openDropdown.classList.contains('show-drop')) {
						openDropdown.classList.remove('show-drop');
					}
				}
			}

		}

	},
	data() {
		return {
			constractSelected: null,
			imgUrl: process.env.VUE_APP_IMG_SRC_API,
			dateGoal: new Date("Aug 01, 2022 18:00:00"),
			fechaActual: new Date().getFullYear(),
			defaultGallery: [
				require('@/assets/images/gallery/1.jpg'),
				require('@/assets/images/gallery/2.jpg'),
				require('@/assets/images/gallery/3.jpg'),
				require('@/assets/images/gallery/4.jpg')
			],
			countDown:{
				months: '',
				days: '',
				hours: '',
				minutes: '',
				seconds: '',
			},
			locales:[
				{
					locale: 'en',
					img: require('@/assets/images/flags/en.png'),
					name: 'English',
				},
				{
					locale: 'es',
					img: require('@/assets/images/flags/es.jpg'),
					name: 'Spanish',
				},
			]
		}
	},
	computed: {
		...mapState('auth',['user', 'myContracts']),
		...mapState('owners',['newsletter','nextReservation', 'ownerInHouse','languages']),
		...mapGetters('auth',['contractSelected']),

		lastNewsletter(){
			return this.newsletter.length > 0 ? this.newsletter[0] : null
		},

		contractExpiresThisYear(){
			const { dateEnd } = this.myContracts.contractSelected //id del contrato
			const maxDateUntilDecember = `${this.fechaActual}-12-31` //fecha max hasta este año
			return moment(maxDateUntilDecember).isSameOrAfter(dateEnd, "day" )
		},
		currentLocale() {
			return this.locales.find( l => l.locale === this.$i18n.locale )
		},
	},
	methods: {
		...mapActions('owners', ['getHotelPerformance']),
		...mapMutations('auth',['setContractSelected','setLanguageUser']),
		...mapMutations('owners',['setContractWasChangedOwners', 'setHotelPerformance']),
    async setLocale(locale){

		const langs = {
			en: 1,
			es: 2,
			pt: 3,
		}
		const dataLang = toJson( this.languages.find( lang => lang.id == langs[locale] ) )

		const langUser = {
			languageCode: dataLang.code,
			languageId: dataLang.id,
			languageName: dataLang.name,
		}

		this.setLanguageUser(langUser)
		this.$i18n.locale = locale
		overWriteVeeValidateLocalize(dataLang.code)
      // overWriteFlatPickrLocalize(dataLang.code)

	  	const idLanguage = this.user.languageId
		const idResort = this.contractSelected.resortsid

		const hotelP = await this.getHotelPerformance({language: idLanguage, resort: idResort, isOwner: true })
		this.setHotelPerformance(hotelP)
    },
		toggleDropdown(){
			// document.getElementById("myDrop").classList.toggle("show");
			document.getElementById("myDropdown").classList.toggle("show");
		},
		async setSelectContract(contract){
			if( contract != '' ){
				const contracts = [...this.myContracts.contracts]
				const contractSelected = contracts.find( ctr => ctr.id === contract )
				const payload = { contracts, contractSelected }
				this.setContractSelected(payload)
				this.setContractWasChangedOwners()
				this.setCurrentPage()
				await this.pushData('onChange', 'changeContract', 'changeContract', this.$route.name ) // push a back del registro
			}
		},
		updateCountDown(){
			const curretDate =  moment()
			const targetTime = moment(this.nextReservation)
			const timeBetween = moment.duration(targetTime.diff(curretDate))

			this.countDown.months =  timeBetween.months()
			this.countDown.days =  timeBetween.days()
			this.countDown.hours = timeBetween.hours()
			this.countDown.minutes = timeBetween.minutes()
			this.countDown.seconds = timeBetween.seconds()

      		setTimeout(() => {
        		this.updateCountDown()
      		}, 60000 ) // me ejecuto cada 60segundos
		},
		selectImage() {
			this.$refs.fileInput.click()
		},
		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files
			if (file && file[0]) {
				let reader = new FileReader
				reader.onload = e => {
					this.previewImage = e.target.result
				}
				reader.readAsDataURL(file[0])
				this.$emit('input', file[0])
			}
		},
		setCurrentPage(){
			let existeCategoryRoom = false
			// let validContract = false
			if (this.myContracts?.contractSelected){
				const { categoryroomId = false, returnType = false, membershipid = false } = this.myContracts?.contractSelected
				existeCategoryRoom = categoryroomId != null
				// validContract = returnType != 2 && membershipid != 1
			}

			if (this.$router.history.current.name == 'my-reservations' && !this.user?.canBook || !existeCategoryRoom) this.$router.push( {name: 'home'} )
			// else if (this.$router.history.current.name == 'my-reports' && validContract ) this.$router.push( {name: 'home'} )
		},
		async goToMyProfile(){
			this.$router.push({name: 'profile' })
			await this.pushData('onClick', 'goToMyAccount', 'goToMyAccountFromTopCard', this.$route.name ) // push a back del registro
		},
	},
}
</script>

<style lang="scss" scoped>

$heighCard: 250px;

.topCards{
    width: 100%;
    // overflow: hidden;
    margin: 0 auto;
    // padding: 80px 0;
    text-align: center;
    // outline: 1px solid red;
    margin-bottom: 1rem;
}

.card__container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr; /*crear una columna de una fracción*/
    grid-template-rows: repeat(4,  $heighCard); /*4 filas de una fracción*/
    grid-template-areas:
    "card1"
    "card2"
    "card3"
    "card4";
    justify-items: center;  /*que sus grid item se centren horizontalmente*/
    gap: 2.5rem;
}

.card__item{
    padding-top: 1rem;
    width: 95%;
    max-width: 540px;
    padding: .5rem 1rem;
    box-shadow:2px 4px 20px -6px hsla(212, 86%, 64%, .5); /*0 en x, 4px en y, 20px de blur y -6px*/
    border-top: 4px solid #231F20 ;
    border-radius: 8px;
    grid-area: card1;
    background-image: url('~@/assets/images/waves/pattern.svg');  /*Lo unico que se cambia es la imagen*/

    background-size: cover;
    background-repeat: no-repeat;
    // z-index: 1;
}

/* El segundo card */
.card__item:nth-child(2){
    grid-area: card2;
    // border-top: 4px solid hsl(0, 78%, 62%);
    padding: unset;
    margin: unset;
}

/* El tercer card */
.card__item:nth-child(3){
    grid-area: card3;
    // border-top: 4px solid hsl(34, 97%, 64%);
}

/* El cuarto card */
.card__item:nth-child(4){
    grid-area: card4;
    // border-top: 4px solid hsl(212, 86%, 64%);
    // margin-bottom: 1rem;
    text-align: left;
}

.card__profile--head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.photo_profile{
  width: 50px;
  min-width: 48px;
}
figure {
  margin: 0;
}
.profile__img{
    width: 100%;
    display: block;
    border-radius: 18px;
    cursor: pointer;
}
.card__profile--detail{
    display: flex;
    gap: 1rem;
    align-items: center;
    text-align: left;
}
.card__profile--name p{
  margin: 0;
  color: black;
  // padding: .2rem .5rem .2rem .3rem;

  font-size: .8rem;
  overflow-wrap: break-word;
  font-weight: bold;
}

.residence {
  color: black;
  // padding: .2rem .5rem .2rem .3rem;
  border-radius: 4px;
  font-size: .85rem;
  overflow-wrap: break-word;
  font-weight: bold;
  margin-top: .5rem;
  text-align: left;
}



.dropbtn {
  background-color: transparent;
  // color: white;
  padding: .5rem;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn svg{
  pointer-events: none;

}

.dropbtn:hover, .dropbtn:focus {
  background-color: #a4abaf;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  margin-top: .3rem;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 50px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
  /* right: .5rem;  */
  cursor: pointer;
 }

.dropdown-content p {
  color: black;
  padding: 0 6px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}

.my-gallery-img {
  height: 250px;
  max-height: 250px;
  overflow: hidden;
}

.my-gallery-img img {
  width: 100%;
  // height: 100%;
  object-fit: cover;
  height: 250px;
  border-radius: 8px;
}

// time counter
.countdown{
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.countdown__item{
  background-color: #231F20;
  // width: 30%;
  padding: 1rem 1rem;
  color: #fff;
}

.countdown__item p{
  margin: 0;
}

.card__name{
    font-size: 1.5rem;
    margin-bottom: 16px;
}

.card__copy{
  color: #231F20;
  font-weight: 200;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__picture{
    width: max-content;
    margin-left: auto; /*Esto manda la img a la derecha detro de su grid item*/
}

.img-newsleter{
  max-width: 100px;
}

.text-checkin{
  display: block;
  margin-top: 1rem;
  color: #595959;
  font-weight: bold;
}

@media (min-width:540px) {
  $heighCard: 280px;

  .card__container{
    display: grid;
    grid-template-columns: 1fr 1fr; /*crear 2 columna de una fracción*/
    grid-template-rows: repeat(2, $heighCard); /*4 filas de una fracción*/
    grid-template-areas:
    "card1 card2"
    "card3 card4"
   ;
    justify-items: center;  /*que sus grid item se centren horizontalmente*/
    gap: 1.5rem;
    // border: 1px solid red;
  }
  .my-gallery-img {
    height: $heighCard;
    max-height: $heighCard;
  }

  .my-gallery-img img {
    height: $heighCard;
    border-radius: 8px;
  }
}

@media (min-width:720px) {
  $heighCard: 260px;
  .card__container{
    grid-template-rows: repeat(2, $heighCard); /*4 filas de una fracción*/
  }

  .my-gallery-img {
    height: $heighCard;
    max-height: $heighCard;
  }

  .my-gallery-img img {
    height: $heighCard;
    border-radius: 8px;
  }
}
@media (min-width:800px) {
  $heighCard: 230px;
  .card__container{
    grid-template-rows: repeat(2, $heighCard); /*4 filas de una fracción*/
  }

  .my-gallery-img {
    height: $heighCard;
    max-height: $heighCard;
  }

  .my-gallery-img img {
    height: $heighCard;
    border-radius: 8px;
  }
}

@media (min-width:1140px) {
  $heighCard: 210px;
  .card__container{
    grid-template-rows: repeat(2, $heighCard); /*4 filas de una fracción*/
  }

  .my-gallery-img {
    height: $heighCard;
    max-height: $heighCard;
  }

  .my-gallery-img img {
    height: $heighCard;
    border-radius: 8px;
  }

  .card__copy{
    -webkit-line-clamp: 3;
  }
}



@media (min-width:1500px) {
  $heighCard: 250px;
  .card__container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,  1fr); /*crear una columna de una fracción*/
    grid-template-rows: repeat(1,  $heighCard); /*4 filas de una fracción*/
    grid-template-areas:
    "card1 card2 card3 card4"
    ;
    justify-items: center;  /*que sus grid item se centren horizontalmente*/
    gap: 1rem;
  }


  .my-gallery-img {
    height: 245px;
    max-height: 245px;
  }

  .my-gallery-img img {
    height: 245px;
    border-radius: 8px;
  }

  .card__copy{
    -webkit-line-clamp: 5;
  }

}

.contractNoExpire span{
  color: #17bd0b;
}

.contractExpire span{
  color: #C32021;
}
</style>
