<template>

  <div class="container-booking-cards">
    <div class="card-booking">
      <div class="content-wrapper">
        <a href="https://www.thefiveshotels.com/beach" target="_blank"><img src="https://exp.cdn-hotels.com/hotels/2000000/1970000/1966300/1966239/df1348bb_z.jpg?impolicy=fcrop&w=1000&h=666&q=medium" alt="The Fives Beach Hotels & Residences"></a>

        <div class="actions-title">
          <h4 class="text-title"> The Fives Beach Hotels & Residences</h4>
        </div>
      </div>
  </div>
  <div class="card-booking">
      <div class="content-wrapper">
        <a href="https://www.thefiveshotels.com/downtown" target="_blank"><img src="https://www.fivesrooftop.com/assets/img/rooftop-the-fives.jpg" alt="The Fives Downtown Hotel & Residences"></a>

        <div class="actions-title">
          <h4 class="text-title">The Fives Downtown Hotel & Residences</h4>
        </div>
      </div>
  </div>
  <div class="card-booking">
      <div class="content-wrapper">
        <a href="https://www.thefiveshotels.com/oceanfront" target="_blank"><img src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/290938227.jpg?k=5fa9b4b20ed51d1be661e8c205134730dd2c3aede5725824cd221b975ed3fd91&o=&hp=1" alt="The Fives Oceanfront - Puerto Morelos"></a>
        <div class="actions-title">
          <h4 class="text-title">The Fives Oceanfront - Puerto Morelos</h4>
        </div>
      </div>
  </div>

</div>

</template>

<script>

import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
   directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed:{
    ...mapState('owners', ['profits']),
  }
}
</script>

<style scoped>
.container-booking-cards {
	 display: grid;
	 grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	 grid-gap: 20px;
	 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
   margin-block-end: 2rem;

}
 .card-booking {
  /* border-top: 3px solid var(--blue); */
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
  background-color: #fff;
  cursor: pointer;

}

 .card-booking .content-wrapper {
	margin: 0 auto;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
 }


.card-booking img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.actions-title{
  position: absolute;
  top: 85%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.text-title{
  color: rgb(255, 255, 255);
  border-radius: 8px;
  font-size: 1.2rem;
  padding: .5rem 1rem;
  /* text-shadow: .2em .2em 0 hsl(200 50% 30%); */
}

.text-title:hover{
  color: #231F20;
  background-color: #f5f1f2;
  transition-duration: 500ms;
}
</style>