<template>
  <b-table :items="companyTable" responsive :fields="fields" class="mb-0" >            
    <template #cell(company)="data">
      <div class="d-flex align-items-center">
        <b-avatar
          rounded
          size="32"
          variant="light-company"
        >
          <b-img
            :src="data.item.avatarImg"
            alt="avatar img"
          /></b-avatar>
        <div>
          <div class="font-weight-bolder">
            {{ data.item.title }}
          </div>
          <div class="font-small-2 text-muted">
            {{ data.item.subtitle }}
          </div>
        </div>
      </div>
    </template>

    <template #cell(category)="data">
      <div class="d-flex align-items-center">
        <b-avatar
          class="mr-1"
          :variant="data.item.avatarColor"
        >
          <feather-icon
            size="18"
            :icon="data.item.avatarIcon"
          />
        </b-avatar>
        <span>{{ data.item.avatarTitle }}</span>
      </div>
    </template>
    
    <template #cell(views)="data">
      <div class="d-flex flex-column">
        <span class="font-weight-bolder mb-25">{{ data.item.viewTitle }}</span>
        <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span>
      </div>
    </template>
    
    <template #cell(revenue)="data">
      {{ '$'+data.item.revenue }}
    </template>
    
    <template #cell(sales)="data">
      <div class="d-flex align-items-center">
        <span class="font-weight-bolder mr-1">{{ data.item.sales+'%' }}</span>
        <feather-icon
          :icon="data.item.loss ? 'TrendingDownIcon':'TrendingUpIcon'"
          :class="data.item.loss ? 'text-danger':'text-success'"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapState } from 'vuex'
export default {    
  data() {
    return {
      // Tabla
      companyTable: [       
        {
          avatarImg: require('@/assets/images/icons/brush.svg'),
          title: 'Zipcar',
          subtitle: 'davcilse@is.gov',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '162',
          viewsub: 'in 5 days',
          revenue: '522.29',
          sales: '62',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/star.svg'),
          title: 'Owning',
          subtitle: 'us@cuhil.gov',
          avatarIcon: 'MonitorIcon',
          avatarColor: 'light-primary',
          avatarTitle: 'Technology',
          viewTitle: '214',
          viewsub: 'in 24 hours',
          revenue: '291.01',
          sales: '88',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/book.svg'),
          title: 'Cafés',
          subtitle: 'pudais@jife.com',
          avatarIcon: 'CoffeeIcon',
          avatarColor: 'light-success',
          avatarTitle: 'Grocery',
          viewTitle: '208',
          viewsub: 'in 1 week',
          revenue: '783.93',
          sales: '16',
          loss: true,
        },
        {
          avatarImg: require('@/assets/images/icons/rocket.svg'),
          title: 'Kmart',
          subtitle: 'bipri@cawiw.com',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '990',
          viewsub: 'in 1 month',
          revenue: '780.05',
          sales: '78',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/speaker.svg'),
          title: 'Payers',
          subtitle: 'luk@izug.io',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '12.9k',
          viewsub: 'in 12 hours',
          revenue: '531.49',
          sales: '42',
          loss: false,
        },
      ],
      fields: [
        { key: 'company', label: 'COMPANY' },
        { key: 'category', label: 'CATEGORY' },
        { key: 'views', label: 'VIEWS' },
        { key: 'revenue', label: 'REVENUE' },
        { key: 'sales', label: 'SALES' },
      ],
    }
  },
  computed:{
    ...mapState('owners', ['profits']),
  }  
}
</script>