<template>
    <div class="dropdown"  @click="toggleDropdown">
        <button class="dropbtncontract" id="btnDrop">         
            {{myContracts.contractSelected.customInfoLong}}
            <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M14 5L7.5 12L1 5" stroke="#000000" stroke-linecap="square"/>
            </svg>
        </button>
        <div id="myDropContract" class="dropdown-content-contract">      
            <p v-for="(contract) in myContracts.contracts" :key="contract.id" @click="setContract(contract)">{{ contract.customInfoLong }}</p>                 
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    mounted() {

    },
    computed:{        
        ...mapState('auth',['user', 'myContracts']),
		idContractSelected(){
			return this.myContracts.contractSelected.id
		}
    },
    methods:{
        toggleDropdown(){      
            document.getElementById("myDropContract").classList.toggle("show-drop");
            document.getElementById("btnDrop").classList.toggle("isDrop")
        },
		setContract(contract){
			// si el idContratoSeleccionado actual es diferente al idContractSelected que viene
			if(this.idContractSelected !== contract.id){
				this.$emit('set-contract-selected', contract.id)			
			}
		}
    }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: inline-block;
  text-align: left;
}

.dropbtncontract {
  background-color: transparent;
  background-color: #e9eff3;
  border-radius: 1rem;

  // color: white;
  padding: .5rem;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  color: #121312;
  font-weight: bold;
}

.dropbtncontract svg {
    margin-left: .5rem;
  	pointer-events: none;
}

.dropdown-content-contract {
  margin-top: .3rem;
  padding: 1rem;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 50px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  /* right: .5rem;  */
  cursor: pointer; 
  transition: 0.2s ease;

 }

.dropdown-content-contract p {
  color: black;
  padding: 0 6px;
  text-decoration: none;
  display: block;
}

.dropdown-content-contract p:hover, .dropdown-content-contract p:focus {
  color: black;
  background-color: #d1e2e6;

}

.dropdown a:hover { background-color: #ddd;}

.dropbtncontract svg{	
  	transition: 0.2s ease;
}

.dropbtncontract.isDrop {
	background-color: #d1e2e6;

}
.dropbtncontract.isDrop svg{
	transform: rotate(180deg);
  	transition: 0.2s ease;
}
.show-drop {display: block;}
</style>