<template>
  <b-alert v-height-fade show dismissible fade class="mb-1 alert-banner" v-if="showExpireBanner">
      <div class="alert-body">                 
        <div class="d-flex justify-content-end pr-1">
          <span>📅 {{$t('Your contract is expiring', {years: countDown.years, months: countDown.months, days: countDown.days, hours: countDown.hours, minutes: countDown.minutes, seconds: countDown.seconds})}} </span>
           
        </div>
      </div>
    </b-alert>    
</template>
 
<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'
export default {
  directives: {
    Ripple,
    heightFade
  },
  created(){  
    this.updateCountDown()    
  }  ,
  data() {
    return {        
      countDown:{
        years: '',
        months: '',
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
      },
    }
  },
  computed: {
    ...mapState('auth',['user', 'myContracts']),  
    showExpireBanner(){
      const existContracts = ( this.myContracts && this.myContracts.contracts.length ) ? true : false // si hay contratos
      if(existContracts){
        const curretDate =  moment()
        const targetTime = moment( this.myContracts.contractSelected.dateEnd )
        const timeBetween = moment.duration(targetTime.diff(curretDate))
        const years = timeBetween.years()
        return (years <= 2 ) ? true : false
      }
      return false
    }   
  },
  methods: {    
   updateCountDown(){
      const existContracts = ( this.myContracts && this.myContracts.contracts.length ) ? true : false // si hay contratos
      if(existContracts){
        const curretDate =  moment()
        const targetTime = moment( this.myContracts.contractSelected.dateEnd )
        const timeBetween = moment.duration(targetTime.diff(curretDate))
  
        this.countDown.years =  timeBetween.years()
        this.countDown.months =  timeBetween.months()
        this.countDown.days =  timeBetween.days()
        this.countDown.hours = timeBetween.hours()
        this.countDown.minutes = timeBetween.minutes()
        this.countDown.seconds = timeBetween.seconds()
        
        setTimeout(() => {             
          this.updateCountDown()          
        }, 1000 ) // me ejecuto cada 60segundos      
      }
    },
    
  },
  watch: {
    myContracts: {
      deep: true,
      async handler() {        
        await this.updateCountDown()        
      },
    },
  },
}
</script>
 
 <style scoped>

.alert-banner{
  background:#ff9f43!important;
  color: white;
  border-radius: 0!important;  
}

.alert-banner .alert-body{  
  color: white;
}

</style>
