<template>
  <b-alert v-height-fade show dismissible fade class="mb-1 alert-banner" v-if="checkIfOwnerHaveAnExecutiveReport">
    <div class="alert-body">
      <div class="d-flex justify-content-end pr-1">
        <!-- <span>📅 {{$t('Your contract is expiring')}} </span> -->
        <span>📄 {{$t('Your executive report is ready')}} {{$t('To see it, please')}} <b-link @click="goToProfits">{{$t('click here')}}</b-link> </span>
      </div>
    </div>
  </b-alert>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'
import { utils } from "@/modules/owners/mixins/utils"

export default {
	mixins:[utils]	,
	directives: {
		Ripple,
		heightFade
	},
	data() {
		return {
			ownerHaveAnExecutiveReport: false
		}
	},
	computed: {
		...mapState('auth',['user', 'myContracts']),
		...mapState('owners', ['profits']),
		checkIfOwnerHaveAnExecutiveReport(){
			const anualReports = this.profits?.anual
			let hasAPendingReport = false

			if (anualReports?.length > 0) {
				const hasAReport = anualReports?.some(report => !report?.authorized)
				hasAPendingReport = hasAReport //&& this.myContracts.contractSelected.returnType == 2
			}

			return hasAPendingReport
		}
	},
	methods: {
		...mapActions('owners', ['pushActionsService']),		
		...mapMutations('owners', ['setSelectedTabProfits']),
		async goToProfits(){
			this.setSelectedTabProfits(2)
			this.$router.push({ name: 'my-reports'}) // lo mando a la vista profits
			// params: { idContract: nuevoContrato.id }
			await this.pushData('onClick', 'goToMyReports', 'goToMyReports', this.$route.name, 'to Tab Annual Executive Report' ) // push a back del registro
		},		
	},
}
</script>

<style scoped>

.alert-banner{
  background:#17bd0b!important;
  color: white;
  border-radius: 0!important;
}

.alert-banner .alert-body{
  color: white;
}

</style>
